import type { RouteRecordRaw, Router } from 'vue-router';
import auth from '@/app/middleware/auth';

const moduleRoute: RouteRecordRaw = {
  path: '/profile',
  name: 'Profile',
  component: () => import('@/shared/layouts/TheLayoutDefault.vue'),
  redirect: { name: 'Profile.Main' },
  meta: {
    middleware: [auth],
  },
  children: [
    {
      path: '',
      name: 'Profile.Wrapper',
      component: () => import('@/modules/profile/pages/Wrapper.vue'),
      children: [
        {
          path: 'info',
          name: 'Profile.Main',
          meta: {
            title: 'Редактирование профиля',
          },
          component: () => import('@/modules/profile/pages/Main.vue'),
        },
        {
          path: 'info',
          name: 'Profile.Security',
          meta: {
            title: 'Безопасность',
          },
          component: () => import('@/modules/profile/pages/Security.vue'),
        },
        {
          path: 'info',
          name: 'Profile.School',
          meta: {
            title: 'Школа',
          },
          component: () => import('@/modules/profile/pages/School.vue'),
        },
      ],
    },
  ],
};

export default (router: Router) => {
  router.addRoute(moduleRoute);
};
