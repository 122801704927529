import titles from './titles';
import buttons from './buttons';

export default {
  titles,
  buttons,
  assignments: {
    assignments_day: 'Задание дня',
  },
  awards: 'Жүлделер',
  no_awards: 'Сізде жүлде жоқ',
  get_awards: 'Тапсырмаларды орындап, жүлделерді алыңыз',
  auth: 'MathCombat-қа кіру',
  sign_in: 'Кіру',
  sign_up: 'Тіркелу',
  forgot_password: 'Құпия сөзді ұмыттыңыз ба?',
  name: 'Аты',
  enter_name: 'Атыңызды енгізіңіз',
  surname: 'Тегі',
  enter_surname: 'Тегіңізді енгізіңіз',
  iin: 'ЖСН',
  enter_iin: 'ЖСН-ді енгізіңіз',
  email: 'Пошта',
  enter_email: 'Поштаны енгізіңіз',
  password: 'Құпия сөз',
  enter_password: 'Құпия сөзді енгізіңіз',
  password_confirmation: 'Құпия сөзді қайталаңыз',
  change: 'Өзгерту',
  save: 'Сақтау',
  cancel: 'Болдырмау',
  accept: 'Қабылдау',
  close: 'Жабу',
  battle: {
    wins: 'Жеңістер',
    congratulations: 'Құттықтаймыз!',
    you_win: 'Сіз МатШайқаста жеңдіңіз!',
    you_win_description: 'МатШайқаста жеңу оңай болған жоқ. Өзіңізге жаңа қарсылас табу үшін лоббиге оралыңыз!',
    you_lose: 'Өкінішке орай сіз МатШайқаста жеңілдіңіз!',
    you_lose_description: 'Үмітіңізді үзбеңіз! Сіз әлі де басқа шайқастарда жеңіске жетесіз! Өзіңізге жаңа қарсылас табу үшін лоббиге оралыңыз!',
    earned_points: 'Жинаған рейтинг ұпайыңыз',
    earned_coins: 'Жинаған тиындарыңыз',
    choose_answer: 'Жауапты таңдау',
    round: 'Раунд',
    your_score: 'Сіздің ұпайларыңыз',
    opponent: 'Қарсылас',
    opponent_score: 'Қарсыластың ұпайлары',
    game_type: 'Ойын түрі',
    no_battles: 'Нажмите Поиск боя что бы отобразить историю, и предлагаю какую то отдельную страницу со всеми его играми',
    search: 'Іздеу...',
    search_opponent: 'Қарсыласты іздеу',
    search_right_opponent: 'Сәйкес қарсыласты іздеудеміз',
    game_ready: 'Сіздің ойыныңыз дайын',
    duel_one_to_one: '“Жекпе-жек”',
  },
  back_to_home: 'Басты бетке оралу',
  profile: {
    edit: 'Профильді өзгерту',
    main: 'Профльдің негізгі ақпараты',
  },
  character: {
    on: 'Киілді',
    get_on: 'Кию',
    buy: 'Сатып алу',
    bought: 'Сатып алынды',
    you_bought: 'Сіз затты сатып алдыңыз',
  },
  inventory: {
    title: 'Инвентарь',
  },
  rating: {
    title: 'Рейтинг',
    your_rating: 'Сіздің рейтингіңіз',
    your_rank: 'Сіздің рангіңіз',
    points: 'Рейтинг ұпайлары',
    solved: 'Шығарған есептер саны',
    place_in_league: 'Лигадағы орын',
    your_league: 'Сіздің лигаңыза',
  },
  theme: {
    light: 'Светлая тема',
    dark: 'Темная тема',
  },
};
